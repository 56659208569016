import { calculateGdd, simpleAve, } from "../../utils/helpers"
import { format } from "date-fns"

// modelData, data, dateOfInterest, isModelInSeason
/* .map(
    (d) => {
        return { ...d, dateDisplay: format(d.date, "yyyy-MM-dd") }
      }
    ) */

export default function ConiferPestLogic(data, dateOfInterest, modelData) {
    const { dashboardDisplay, base } = modelData
    const dailyDataWithGdd = calculateGdd(data.dailyData, base, 0, simpleAve)

    // Determine management guide message
    let selectedDate = dailyDataWithGdd.find(
        (day) => day.dayOfYear === dateOfInterest.dayOfYear
        )
    if (!selectedDate) {
        selectedDate = dailyDataWithGdd.find((d) => d.isForecast)
    }

    /* let managementGuideMsg = null
    if (selectedDate) {
        const msg = gddRangeToMsg(
          elements.managementGuide.species.[],
          selectedDate.gdd
        )
        if (msg) {
          managementGuideMsg = msg
          managementGuideMsg.explanation = elements.managementGuide.explanation
        }
    } */

    const mData = dailyDataWithGdd.map((day) => {
        let avgt = "N/A"
        if (day.maxt !== "M" || day.mint !== "M") {
          avgt = Number(((day.mint + day.maxt) / 2).toFixed(1))
        }
    
        return {
          date: day.date,
          dateDisplay: format(day.date, "yyyy-MM-dd"),
          ms: day.ms,
          dayOfYear: day.dayOfYear,
          dd: day.dd,
          gdd: day.gdd,
          isForecast: day.isForecast,
          mint: day.mint,
          maxt: day.maxt,
          avgt,
          low: null,
          moderate: null,
          high: null,
        }
      })
    let tableData = [...mData]
    if (mData.length >= 8) {
        tableData = mData.slice(
            dateOfInterest.dayOfYear - 3,
            dateOfInterest.dayOfYear + 5
        )
    }
    const csvData = mData.map((day) => {
        return {
          Date: day.dateDisplay,
          "Daily DD": day.dd,
          [dashboardDisplay]: day.gdd,
        }
    })

    return {
        data: mData,
        // managementGuideMsg,
        selectedDate,
        tableData,
        csvData,
    }
}