import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Lorem ipsum.
      </p>
    </>
  )
}
