/* 
Conifer Pests Model

This React.js web app uses a simple average base 50 Fahrenheit degree day model to monitor life stage development of select conifer insect pests from 1 Mar of a selected year. A degree day range is assigned to each pest life stage, and then filtered for display using the accumulated base 50F value of the chosen date of interest.

Insects, life stage content, and related metadata are provided by Dr. Elizabeth Lamb (eml38@cornell.edu), Senior Extension Associate and Ornamentals Coordinator at New York State Integrated Pest Management, which is part of Cornell Cooperative Extension at Cornell University.

Author:     Dan Olmstead, New York State Integrated Pest Management
Email:      dlo6@cornell.edu
Created:    6/11/2022
Updated:    6/26/2022
*/

import React from "react";
import content from "./content.json";
import modelData from "./conifer-pests.json";
import ModelLayout from "../../components/models/modelLayout";
import Dropdown from "./_dropdown";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import Acknowledgements from "./_acknowledgements";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";
import { CSVLink } from "react-csv";

// CONFIGURE VISIBLE ELEMENTS USING ASSIGNED PRIORITIES IN CONFIG.JSON
const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

// DEFINE THE STANDARD OUTPUT VARIABLES USING A FUNCTION
export default function ConiferPests() {
  const h1Text = modelData.title;
  const { elements, seasonEnd } = modelData;
  const { moreInfo } = elements;
  const { isAuthenticated, user } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);
  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  const DATA = data?.dailyData;

  // CONFIGURE A SWITCH THAT VERIFIES DATA ARE PRESENT FOR ADDITIONAL MANIPULATION. IF 'status' OF 'DATA' IS 'undefined' CALCULATIONS ARE ABORTED TO AVOID ERRORS.
  let STATUS = null;

  if (DATA === null) {
    STATUS = null;
  } else if (DATA === undefined) {
    STATUS = undefined;
  } else {
    STATUS = "valid";
  }

  // CALCULATE DAILY BASE 50F DEGREE DAYS FROM JAN 1.
  if (STATUS === "valid") {
    for (let i = 0; i < DATA.length; i++) {
      const BASE_TEMP_F = 50;
      const RELATIVE_HEAT_UNITS =
        (DATA[i].maxt + DATA[i].mint) / 2 - BASE_TEMP_F;
      if (RELATIVE_HEAT_UNITS > 0) {
        DATA[i].ddFromJan1 = Math.round(RELATIVE_HEAT_UNITS);
      } else {
        DATA[i].ddFromJan1 = 0;
      }
    }
  }

  // CALCULATE DAILY BASE 50F DEGREE DAYS FROM MARCH 1 IF 'status' IS 'valid.'
  if (STATUS === "valid") {
    for (let i = 0; i < DATA.length; i++) {
      if (DATA[i].dayOfYear < 60) {
        DATA[i].ddFromMar1 = 0;
      } else {
        const BASE_TEMP_F = 50;
        const RELATIVE_HEAT_UNITS =
          (DATA[i].maxt + DATA[i].mint) / 2 - BASE_TEMP_F;
        if (RELATIVE_HEAT_UNITS > 0) {
          DATA[i].ddFromMar1 = Math.round(RELATIVE_HEAT_UNITS);
        } else {
          DATA[i].ddFromMar1 = 0;
        }
      }
    }
  }

  // EXTRACT A DATA SUBSET FOR CSV DOWNLOAD.

  function CSV_DATA() {
    if (STATUS === "valid") {
      let JAN_1_ACCUMULATION = 0;
      let MAR_1_ACCUMULATION = 0;
      for (let i = 0; i < DATA.length; i++) {
        JAN_1_ACCUMULATION = JAN_1_ACCUMULATION + DATA[i].ddFromJan1;
        MAR_1_ACCUMULATION = MAR_1_ACCUMULATION + DATA[i].ddFromMar1;
        DATA[i].ddFromJan1Accum = JAN_1_ACCUMULATION;
        DATA[i].ddFromMar1Accum = MAR_1_ACCUMULATION;
      }

      let DATA_OBJECT = Object.values(DATA).map((item) => {
        return {
          date: item.serverDate,
          dailyDegreeDays50F: item.ddFromJan1,
          degreeDaysFromJan1: item.ddFromJan1Accum,
          degreeDaysFromMar1: item.ddFromMar1Accum,
        };
      });

      let CSV_STRING = "date,dailyDegDays50F,degDays50FFromMar1\n";
      for (let i = 0; i < DATA_OBJECT.length; i++) {
        CSV_STRING =
          CSV_STRING +
          String(DATA_OBJECT[i].date) +
          "," +
          String(DATA_OBJECT[i].dailyDegreeDays50F) +
          "," +
          String(DATA_OBJECT[i].degreeDaysFromMar1) +
          "\n";
      }
      return CSV_STRING;
    }
  }
  CSV_DATA();

  // GET REFERENCE ARRAY FOR MODEL
  const ALL_CONTENT = Object.values(content.insects).map((item) => {
    return {
      value: item.name,
      scientificName: item.scientificName,
      plantHosts: item.plantHosts,
      lifeStages: item.lifeStages,
    };
  });

  // CREATE REFERENCE OBJECTS
  const LIST_OF_INSECTS = Object.values(ALL_CONTENT).map((item) => {
    return { value: item.value, label: item.value };
  });

  // SET THE SPECIES
  const [species, setSpecies] = React.useState(LIST_OF_INSECTS[0]);
  const SPECIES = species;
  const SPECIES_CONTENT = species.value;

  // EXTRACT LIFE STAGE DATA FILTER
  const LIFE_STAGE_DATA = Object.values(ALL_CONTENT).filter(function (item) {
    return item.value === SPECIES.value;
  });
  const LIFE_STAGES = Object.values(LIFE_STAGE_DATA[0].lifeStages).map(
    (item) => {
      return { label: item.name, value: item.name };
    }
  );
  var [lifeStage, setLifeStage] = React.useState("-");
  const SCIENTIFIC_NAME = LIFE_STAGE_DATA[0].scientificName;
  const PLANT_HOSTS = LIFE_STAGE_DATA[0].plantHosts;
  const LIFE_STAGE_CONTENT = LIFE_STAGE_DATA[0].lifeStages;
  var X = LIFE_STAGE_DATA[0].lifeStages[0];
  var Y = Object.values(LIFE_STAGE_CONTENT).filter(function (item) {
    return item.name === lifeStage.value;
  });
  const X_isEmpty = Object.keys(X).length === 0;
  const Y_isEmpty = Object.keys(Y).length === 0;

  // EXTRACT LIFE STAGE CONTENTS
  let CHOSEN_LIFE_STAGE = {};
  let PEST_STATUS_RESULT = {};
  let SCOUTING_RESULT = {};
  let PHENOLOGICAL_MARKERS_RESULT = {};
  let PEST_MANAGEMENT_RESULT = {};
  let BIOLOGICAL_CONTROL_RESULT = {};

  if (X_isEmpty === false && Y_isEmpty === true) {
    CHOSEN_LIFE_STAGE = X.name;
    PEST_STATUS_RESULT = X.content[0].pestStatus;
    SCOUTING_RESULT = X.content[0].scouting;
    PHENOLOGICAL_MARKERS_RESULT = X.content[0].phenologicalMarkers;
    PEST_MANAGEMENT_RESULT = X.content[0].pestManagement;
    BIOLOGICAL_CONTROL_RESULT = X.content[0].biocontrol;
  } else {
    CHOSEN_LIFE_STAGE = Y[0].name;
    PEST_STATUS_RESULT = Y[0].content[0].pestStatus;
    SCOUTING_RESULT = Y[0].content[0].scouting;
    PHENOLOGICAL_MARKERS_RESULT = Y[0].content[0].phenologicalMarkers;
    PEST_MANAGEMENT_RESULT = Y[0].content[0].pestManagement;
    BIOLOGICAL_CONTROL_RESULT = Y[0].content[0].biocontrol;
  }

  // DEFINE LIFE STAGE DISPLAY VARIABLES
  var PEST_STATUS = PEST_STATUS_RESULT;
  var SCOUTING = SCOUTING_RESULT;
  var PHENOLOGICAL_MARKERS = PHENOLOGICAL_MARKERS_RESULT;
  var PEST_MANAGEMENT = PEST_MANAGEMENT_RESULT;
  var BIOLOGICAL_CONTROL = BIOLOGICAL_CONTROL_RESULT;

  // DETERMINE ACCUMULATED DEGREE DAYS SINCE MARCH 1
  let mData = null;
  if (data && isModelInSeason) {
    mData = modelLogic(data, dateOfInterest, modelData, isModelInSeason);
  }

  // EXTRACT TABLE DATA

  if (STATUS === "valid") {
    var TABLE_DATA = mData.tableData;
    var INPUT = DATA;
    let INDEX = 0;
    for (let i = 0; i < TABLE_DATA.length; i++) {
      INDEX = TABLE_DATA[i].dayOfYear - 1;
      TABLE_DATA[i].dd50F = INPUT[INDEX].ddFromJan1;
      TABLE_DATA[i].dd50FTotalJan1 = INPUT[INDEX].ddFromJan1Accum;
      TABLE_DATA[i].dd50FTotalMar1 = INPUT[INDEX].ddFromMar1Accum;
    }
  }

  if (STATUS === "valid") {
    var DEGREE_DAYS_TODAY = TABLE_DATA[2].dd50FTotalMar1;
  }

  // CALCULATED PREDICTED LIFE STAGE
  const LIFE_STAGE_RANGES = Object.values(LIFE_STAGE_CONTENT).map((item) => {
    return { value: item.name, range: item.range };
  });
  let PREDICTED_LIFE_STAGE = {};
  for (let i = 0; i < LIFE_STAGE_RANGES.length; i++) {
    if (
      DEGREE_DAYS_TODAY >= LIFE_STAGE_RANGES[i].range[0] &&
      DEGREE_DAYS_TODAY < LIFE_STAGE_RANGES[i].range[1]
    ) {
      PREDICTED_LIFE_STAGE = LIFE_STAGE_RANGES[i].value;
    }
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const isVisible = station && mData && !isLoading;

  console.log(LIST_OF_INSECTS);

  /* Return html output */
  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-2xl lg:text-3xl"}
      moreInfo={<MoreInfo station={station} moreInfo={moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgements}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      <div className={`mb-16 sm:mb-18 md:mb-20`}>
        {isModelInSeason && isVisible && (
          <>
            <div className="px-6 py-2 bg-white rounded-lg shadow-lg">
              {
                <div className="flex items-center mt-5">
                  <h1>
                    <b>Select an insect</b>
                  </h1>
                </div>
              }{" "}
              {
                <div className="flex items-center mt-5 mb-6">
                  <Dropdown
                    width="w-64"
                    id="species-selection"
                    placeholder="Select a conifer pest"
                    ariaLabel="pest species"
                    list={LIST_OF_INSECTS}
                    item={species}
                    setUserItem={setSpecies}
                  ></Dropdown>
                </div>
              }{" "}
              {
                <div className="mt-2">
                  <p>
                    <b>{DEGREE_DAYS_TODAY}</b> growing degree days (base 50˚F
                    simple average) have accumulated since March 1 and{" "}
                    {SPECIES_CONTENT} <b>{PREDICTED_LIFE_STAGE}</b> are
                    predicted. Scout your trees to verify, and choose the
                    correct <u>field-validated</u> life stage below to view
                    management guidelines.
                  </p>
                </div>
              }
              {
                <div className="flex items-center mt-2">
                  <h1>
                    <b>Select a field-validated life stage</b>
                  </h1>
                </div>
              }
              {
                <div className="flex items-center mt-5 mb-6">
                  <Dropdown
                    width="w-64"
                    id="life-stage-selection"
                    placeholder={"Select life stage"}
                    ariaLabel="life stage"
                    list={LIFE_STAGES}
                    item={lifeStage}
                    setUserItem={setLifeStage}
                  ></Dropdown>
                </div>
              }
            </div>
          </>
        )}
      </div>

      {/* Management Guide */}
      {isVisible && showManagementGuide && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <div className="px-6 py-2 bg-white rounded-lg shadow-lg">
            <h1 className="mt-5">
              <b>{SPECIES_CONTENT}</b>, <i>{SCIENTIFIC_NAME}</i>
            </h1>
            <h2 className="mt-0">
              <b>{CHOSEN_LIFE_STAGE} stage</b>
            </h2>
            <h3 className="mt-0">Hosts include {PLANT_HOSTS}</h3>
            <h2 className="mt-5">
              <b>Pest Status</b>
            </h2>
            <p>{PEST_STATUS}</p>
            <h2>
              <b>Scouting</b>
            </h2>
            <p>{SCOUTING}</p>
            <h2>
              <b>Phenological Markers</b>
            </h2>
            <p>{PHENOLOGICAL_MARKERS}</p>
            <h2>
              <b>Pest Management</b>
            </h2>
            <p>{PEST_MANAGEMENT}</p>
            <h2>
              <b>Biological Control</b>
            </h2>
            <p>{BIOLOGICAL_CONTROL}</p>
          </div>
        </div>
      )}
      {/* Results Table */}
      {isModelInSeason && isVisible && showResultsTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <div className="px-6 py-2 bg-white rounded-lg shadow-lg">
            <h1 className="mt-5">
              <b>Degree Day Summary (base 50˚F simple average)</b>
            </h1>
            <button
              type="button"
              aria-label="Download a C S V file summary of daily and accumulated degree day values."
              className="mt-5 inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
            >
              <svg
                className="-ml-0.5 mr-2 h-4 w-4"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
              </svg>
              <CSVLink
                className="text-white no-underline"
                filename={`newa_conifer_pests_degree_day_summary_${Date().toLocaleString()}`}
                data={CSV_DATA()}
              >
                Download CSV
              </CSVLink>
            </button>
            <ResultsTable
              station={station}
              modelData={modelData}
              tableData={TABLE_DATA}
              csvData={mData.csvData}
            ></ResultsTable>
          </div>
        </div>
      )}
    </ModelLayout>
  );
}
// MARKUP...
