import React from "react"

export default function ResultsTable({
  station,
  modelData,
  tableData,
}) {

  if (!tableData) return null
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex items-center">
          <span className="inline-block py-2 mr-4">
            <a
              className="text-xs sm:text-sm"
              href={`http://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              National Weather Service forecast details for this location
            </a>
          </span>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-white uppercase border-r border-gray-200 bg-secondary-800">
                    Date
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white uppercase border-b border-r border-gray-200 bg-secondary-800">
                    Daily
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white uppercase border-b border-r border-gray-200 bg-secondary-800">
                    From Mar 1
                  </th>
                </tr>
                <tr className="text-center">
                  
                </tr>
              </thead>
              <tbody className="bg-white">
                {tableData.map((day, i) => {
                  return (
                    <tr
                      key={day.ms}
                      className={
                        i === 2 ? `font-extrabold text-center` : `text-center`
                      }
                    >
                      <td
                        className={`text-xs sm:text-sm px-6 h-4 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        {day.dateDisplay}
                        <span className="flex flex-col items-center">
                          <small className="font-bold text-gray-500">
                            {day.isForecast ? "Forecast" : ""}
                          </small>
                        </span>
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        {day.dd50F}
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6`}
                      >
                        <span
                          className={`rounded w-14 sm:w-20 py-1 inline-block`}
                        >
                          {day.dd50FTotalMar1}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <p></p>
        </div>
      </div>
    </div>
  )
}
